<template>
  <v-widget
    color="primary"
    dark
    class="px-4 pt-4"
    v-bind="$attrs"
    :class="{
      'account-switch': (user && !user.broker) || !whiteBranding
    }"
  >
    <template #content>
      <v-row dense>
        <v-col cols="12" class="d-flex align-center">
          <v-icon class="mr-4">mdi-bank</v-icon>
          <span class="text-title white--text">{{ $t(title) }}</span>
        </v-col>
      </v-row>
      <v-row dense class="d-flex flex-row align-baseline">
        <v-col cols="4">
          <v-autocomplete
            :filter="filterObject"
            class="account-select"
            full-width
            dense
            item-text="code"
            :items="items"
            return-object
            hide-details
            :value="account && account"
            @input="handleAccount"
            ref="accountSwitch"
          >
            <template #item="{ item }">
              <p>
                <span class="font-weight-bold"> {{ item.code }} </span><br />
                <span class="grey--text text--darken-1">
                  {{ item.firstname }} {{ item.lastname }}
                </span>
                <span class="font-weight-bold">({{ item.accountNumber }})</span>
              </p>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="8" class="text-center ">
          <div class="d-flex flex-row justify-center">
            <span class="text-description white--text text-uppercase">{{ account.firstname }} {{ account.lastname }}</span>
          </div>
          <div class="d-flex flex-row justify-center">
            <span class="text-description white--text text-uppercase">({{ account.accountNumber }})</span>
          </div>
        </v-col>
      </v-row>
    </template>
  </v-widget>
</template>

<script>
import VWidget from '@/components/commons/VWidget'
import { mapState, mapActions } from 'vuex'

export default {
  components: { VWidget },
  props: {
    title: {
      type: String,
      default: 'portal_account_switch_title'
    },
    items: {
      type: Array
    }
  },
  computed: {
    ...mapState('account', ['account']),
    ...mapState('auth', ['user']),
    ...mapState('setting', ['whiteBranding']),
  },
  methods: {
    ...mapActions('account', ['setAccount']),
    handleAccount(account) {
      this.$refs.accountSwitch.blur()
      this.setAccount(account)
      this.$emit('accountSwitch')
    },
    filterObject(item, queryText) {
      let fullName =
        item.firstname.toLocaleLowerCase() +
        ' ' +
        item.lastname.toLocaleLowerCase()
      return (
        item.code.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        fullName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    }
  }
}
</script>

<style lang="sass">
.text-title
  font-size: 1.143rem

.text-description
  font-size: 1.043rem

.account-switch
  background: transparent linear-gradient(116deg, #00559e 0%, #0090df 100%) 0% 0% no-repeat padding-box

.account-select
  font-size: 1.329rem
  ::v-deep .v-input__slot::before
    border-style: none !important


.account-select.v-text-field > .v-input__control > .v-input__slot::before
  border: none

.account-select
  .v-icon
    font-size: 40px
  .v-autocomplete__content.v-menu__content
    border-radius: 10px !important

@media only screen and (min-width: 480px)
  .text-title
    font-size: 1.429rem
</style>
